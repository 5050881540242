import React from "react"

const Intro = () => {
  return (
    <div class="py-40 ">
      <div class="bg-black p-8 lg:w-4/6  mx-auto rounded-lg   text-white bg-opacity-50">
        <div class="lg:px-16 px-4">
          <span class="text-center lg:text-3xl text-2xl">
            An ideal location for both business and leisure travelers with
            luxury spacious rooms are designed for optimal comfort and they all
            offer a unique view of the lake.
          </span>
        </div>

        <div class="grid grid-row-1 lg:grid-cols-2 grid-cols-1  gap-6 py-12 ">
          <div>
            <span class="text-lg">
              Our rooftop terrace bar allows you to have unprecedented view of
              the lake and its stunning surroundings.
            </span>
          </div>

          <div>
            <span class="text-lg">
              our vast collection of rooms offers opportunity to choose your
              favorite one with option for personal customization.
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
