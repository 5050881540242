import React from "react"

const Activities = () => {
  return (
    <div class="grid grid-row-1 lg:grid-cols-2 grid-cols-1  gap-12  my-16  bg-gray-100 ">
      <div class="flex items-center justify-center">
        <img
          src="/images/kayak_lake_kivu.jpg"
          alt="Kayak in Lake Kivu"
          class="lg:w-9/12 w-full rounded-lg"
        />
      </div>
      <div class="lg:py-20 py-10 px-6 lg:px-20">
        <div class="divide-y-2 divide-gray-300 divide-solid  divide-opacity-50 inline  py-6">
          <div class="py-6">
            <span class="text-2xl font-medium">
              {" "}
              Get lost along lake Kivu impressive landscapes and undiscovered
              islands
            </span>
          </div>
          <div class="lg:py-8 md:py-4 ">
            <p>
              {" "}
              <span class="text-lg">
                {" "}
                Explore impressive scenery, discover birds and wildlife, marvel
                at traditional fishing boats, meet friendly communities and find
                tranquillity on the safe, clean water.{" "}
              </span>
            </p>
            <p class="pt-5">
              <span class="text-lg">
                {" "}
                Our team of trained Rwandan native born local guides means, you
                can discover in depth local culture and beautifully attraction
                points include – Hot spring, night fishing tour, traditional
                dance and more
              </span>
            </p>
          </div>
        </div>

        <div>
          <button
            class=" my-4 px-5  py-2  items-center justify-center rounded-md bg-black text-white font-medium capitalize"
            type="submit"
          >
            Discover Lake Kivu
          </button>
        </div>
      </div>
    </div>
  )
}

export default Activities
