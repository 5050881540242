import React from "react"
import { Link } from "gatsby"

const Dinning = () => {
  return (
    <div class="dinning">
      {" "}
      <div class="grid grid-row-1 lg:grid-cols-2 grid-cols-1  gap-12  my-16">
        <div></div>
        <div class="bg-blur p-10 lg:text-center bg-blue-600 mx-6 lg:mx-32 h-100 rounded-xl  ">
          <p class="text-white  text-xl lg:text-xl font-light">
            The restaurant offers you to taste the local specialties such as
            fish (tilapia and isambaza) or goat skewers and grilled beef on a
            bed of makala and more.
          </p>

          <p class="text-white text-xl lg:text-xl font-light pt-7">
            our Bar offers a vast collection of popular wines, liquors, beers
            from top brands and breweries across the world.
          </p>

          <Link to="/menu">
            <div class="flex items-center justify-center py-6">
              <button
                class=" outline-none focus:outline-none hover:outline-none my-4 px-5  py-2  items-center justify-center rounded-md bg-black text-white   capitalize"
                type="submit"
              >
                Explore Menu
              </button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Dinning
