import React from "react"
import RoomCard from "./RoomCard"

const rooms = [
  {
    type: "deluxe",
    title: "Deluxe Rooms",
    description:
      "A classical minimalist style suite with high quality materials. Deluxe rooms are recommended for people who like spacious accommodation in reasonable price.",
    url: "",
    img: "/images/deluxe_room.jpg",
  },

  {
    type: "king-studio",
    title: "King Studio Rooms ",
    description:
      "A luxury room with king-size bed, popular with business travelers with large airspace for inside room office and meeting.",
    url: "",
    img: "/images/king_studio_room.jpg",
  },
]
function RoomList() {
  return (
    <div class="py-16 lg:py-32 sm:px-3 md:px-32 grid  md:grid-cols-2  gap-4 md:gap-12">
      {rooms.map(room => (
        <div key={room.title} class="box">
          <RoomCard {...room} />
        </div>
      ))}
    </div>
  )
}

export default RoomList
