import React from "react"

function Description(props) {
  return <div class=" pl-4 border-l-1 border-gray-300">{props.children}</div>
}

function RoomDescription() {
  return (
    <div class="px-16 py-16 bg-gray-100">
      <div className="py-16">
        <span className="text-3xl italic font-light">
          {" "}
          What make our rooms so unique...{" "}
        </span>
      </div>
      <div class="grid overflow-hidden sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-2  gap-4 lg:gap-12">
        <div class="box">
          <Description>
            Stylishly designed chic bedrooms with well-appointed functional
            space
          </Description>
        </div>
        <div class="box">
          <Description>
            High-thread count bedding and bath amenities
          </Description>
        </div>
        <div class="box">
          <Description>
            All rooms equipped with 32-inch flat screen televisions with full HD
            and high quality sound system
          </Description>
        </div>
        <div class="box">
          <Description>
            Free high speed Wi-Fi, Gadgets charging ports.
          </Description>
        </div>
        <div class="box">
          <Description>
            Motion activated LED guidelight below bed and backlighted glass
            panels to add ambience
          </Description>
        </div>
        <div class="box">
          <Description>
            Full-height art image and comfortable leather lounge chair in all
            rooms
          </Description>
        </div>
      </div>
    </div>
  )
}

export default RoomDescription
